.app {
  text-align: center;
  overflow: hidden !important;
}

.app *::-webkit-scrollbar {
  width: 0.75em;
  /* margin-left: 0.25em; */
}

.app *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  /* border-radius: 0.5em; */
}

.app *::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.75);
  outline: 0px solid slategrey;
  /* border-radius: 0.5em; */
}

.appBody {
  width: 100vw;
  height: calc(100vh - 15vh - 7vh);
  padding: 2.5vh 2.5vw;
  perspective: 100vh;
  overflow-y: auto;
}

/* Content Styles */
.verticalCenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.slideIn_Top {
  animation: SlideIn_Top 0.5s;
}

@keyframes SlideIn_Top {
  from {
    visibility: hidden;
    opacity: 0;
    top: 25%;
    transform: rotateX(25deg);
  }
  to {
    visibility: visible;
    opacity: 1;
    top: 50%;
    transform: rotateX(0deg);
  }
}

.slideIn_Bottom {
  animation: SlideIn_Bottom 0.5s;
}

@keyframes SlideIn_Bottom {
  from {
    visibility: hidden;
    opacity: 0;
    top: 75%;
    transform: rotateX(-25deg);
  }
  to {
    visibility: visible;
    opacity: 1;
    top: 50%;
    transform: rotateX(0deg);
  }
}

.slideIn_Right {
  animation: SlideIn_Right 0.75s;
}

@keyframes SlideIn_Right {
  from {
    visibility: hidden;
    opacity: 0;
    right: -100%;
    transform: rotateY(50deg);
  }
  to {
    visibility: visible;
    opacity: 1;
    right: 0%;
    transform: rotateY(0deg);
  }
}

.slideIn_Left {
  animation: SlideIn_Left 0.75s;
}

@keyframes SlideIn_Left {
  from {
    visibility: hidden;
    opacity: 0;
    left: -100%;
    transform: rotateY(-50deg);
  }
  to {
    visibility: visible;
    opacity: 1;
    left: 0%;
    transform: rotateY(0deg);
  }
}
/* Content Styles */

/* ParallaxBackDrop */
.plx {
  z-index: -10000;
  position: fixed;
  top: -25vh;
  left: -25vw;
  transition: background-position 0.125s linear;

  width: 150vw;
  height: 150vh;

  opacity: 0.3;
  background: url(./images/tile.jpg);
  background-repeat: repeat;
  background-size: calc(100% / 4);
  background-position: 0% 0%;
}
/* ParallaxBackDrop */

/* HeaderBar */
.headerBar {
  z-index: 1001;
  position: relative;
  width: 100vw;
  height: 15vh;
  padding: 2.5vh;
  box-shadow: 0vh 0.25vh 2.5vh #000000;
}
.headerBarTitle {
  font-size: 5vh;
  font-weight: bold;
  line-height: 5vh;
}
.headerBarSubTitle {
  font-size: 3vh;
  line-height: 5vh;
}
/* HeaderBar */

/* Landing Screen */
.btn-ls {
  padding: 2vh !important;
  font-size: 5vh !important;
  box-shadow: 0.25vh 0.25vh 2.5vh #000000;
}

.btn-ls-2 {
  visibility: hidden;
  animation: btn-ls-2-enter 0.75s 0.25s ease forwards;
}

.btn-ls-pulse {
  animation: btn-ls-enter 0.75s ease 1,
    btn-ls-pulse 1s 0.75s ease-in-out infinite alternate;
}

@keyframes btn-ls-enter {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    box-shadow: 0.25vh 0.25vh 0.25vh #000000;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    box-shadow: 0.75vh 0.75vh 2.5vh #333333;
  }
}

@keyframes btn-ls-2-enter {
  0% {
    visibility: visible;
    opacity: 0;
    transform: scale(0, 0);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes btn-ls-pulse {
  from {
    transform: scale(1, 1);
    box-shadow: 0.75vh 0.75vh 2.5vh #333333;
  }
  to {
    transform: scale(0.9, 0.9);
    box-shadow: 0.25vh 0.25vh 0.25vh #000000;
  }
}
/* Landing Screen */

/* Start Game Screen */
.lbl-sg {
  width: 100%;
  font-size: 7vw !important;
  margin-bottom: 2.5vh;
}
/* Start Game Screen */

/* FooterBar */
.footerBar {
  z-index: 1001;
  width: 100vw;
  height: 7vh;
  padding: 1vh;
  position: absolute;
  bottom: 0;
}
/* FooterBar */

/* Themes */
.default_theme {
  color: #ffffff;
  background-color: rgba(103, 137, 171, 0.75) /*#6789ab*/;
}
.red_theme {
  color: #ffffff;
  background-color: rgba(255, 0, 0, 0.75);
}
.green_theme {
  color: #ffffff;
  background-color: rgba(0, 255, 0, 0.75);
}
.blue_theme {
  color: #ffffff;
  background-color: rgba(0, 0, 255, 0.75);
}
/* Themes */

/* Default Styles */
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
